* {
  font-size: 10pt;
}
body {
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
font-size: 9pt;
}

pre {
  white-space: pre-wrap;
  word-break: break-word;
  background-color: #f1f1f1;
  padding: 8pt;
}

.w-5 { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }

.font-size-1 { font-size: 0.25rem; }
.font-size-2 { font-size: 0.5rem; }
.font-size-3 { font-size: 1rem; }
.font-size-4 { font-size: 1.5rem; }
.font-size-5 { font-size: 3rem; }

.opacity-0 { opacity: 0%;}
.opacity-25 { opacity: 25%;}
.opacity-50 { opacity: 50%;}
.opacity-75 { opacity: 75%;}
.opacity-100 { opacity: 100%;}

.boxed {
border: 1px solid DarkGrey;
padding-left: 1rem;
padding-right: 1rem;
}

.clickable {
cursor: pointer;
}
.clickable:hover {
font-weight: bold;
}

.language-flag {
width: 20px;
margin-right: 2px;
padding-bottom: 3px;
}

.menu-icon {
color: darkred;
width: 24px;
text-align: center;
}
.dropdown-scroll {
max-height: 310px;
overflow-y: auto;
}

.dropdown-scroll-annotator {
  max-height: 310px;
  width: 300px;
  overflow-y: auto;
}

.dropdown-item.disabled .menu-icon,
.dropdown-item:disabled .menu-icon {
color: #6c757d;
}

#language-selector {
border-radius: 7px;
height: 26pt;
}
#language-selector.dropdown-toggle::after {
vertical-align: 0.2em;
color: grey;
}
#language-selector:focus {
box-shadow: none;
}
#language-selector:hover {
background-color: #f1f1ee;
}

#user-button {
border: 0;
background-color: white;
}
#user-button:focus {
box-shadow: none;
}
#user-button:hover {
background-color: white;
}
#user-button::after {
display: none;
}
.username {
background-color: white;
border: 0;
font-weight: bold;
border-radius: 7px;
height: 26pt;
padding: 7px;
}
.username:hover {
background-color: #f1f1ee;
}
.user-icon {
font-size: 16pt;
margin-right: 10px;
}

.profile-card {
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
}

.label {
font-style: italic;
}

.blur-layer {
z-index: 10;
position: absolute;
width: 100%;
height: 100%;
background: white;
opacity: 0.7;
display: flex;
align-items: center;
justify-content: center;
}

.border {
margin-top: 6pt;
margin-bottom: 6pt;
/*border-radius: 5px;*/
border: 1px solid DarkGrey !important;
}

.tableheader {
font-style: italic;
margin-bottom: 0pt;
padding-bottom: 5pt;
border-bottom: 1px solid lightgray;
}

.bottomrow {
padding-bottom: 4pt;
border-bottom: 1px solid lightgray;
}

.bottomrow:last-child {
padding-bottom: 0pt;
border-bottom: none !important;
}

.bottomrow-bold {
padding-bottom: 20px;
margin-bottom: 20px;
border-bottom: 3px solid lightgray;
}

.bottomrow-bold:last-child {
padding-bottom: 0;
margin-bottom: 0;
border-bottom: none !important;
}

.modalFilters {
padding-bottom: 1rem;
}

.backButtonLabel {
font-size: 1rem;
align-self: center;
}

.modalFooter {
width: 100%;
justify-content: space-between;
}

.modalPagination {
display: inline-flex;
}

.modalTitle {
text-align: center;
padding-bottom: 1rem;
color: crimson;
}

.pageSelector {
width: 46px;
text-align: center;
font-weight: 600;
color: black;
}

.td-accepted {
text-decoration: none;
font-size: smaller;
color: green;
font-weight: bold;
}

.td-deleted {
text-decoration: line-through;
font-size: smaller;
}

.td-normal {
text-decoration: none;
font-size: smaller;
color: #007bff;
}

.td-label {
text-decoration: none;
font-size: smaller;
}

.td-row {
background-color: lightgray;
border-radius: 5px;
border: 1px solid lightgray;
margin-bottom: 5pt;
min-height: 37px;
}

.td-row:last-of-type {
margin-bottom: 0;
}

.tablecenter {
text-align: center
}

.mappingtype {
text-align: center;
font-size: smaller !important;
font-weight: bold;
color:DarkGrey;
text-transform: uppercase;
}

.grouping {
margin: 4pt;
}

.groupborder {
margin-top: 6pt;
margin-bottom: 10pt;
padding-bottom: 14pt;
/*border-radius: 5px;*/
border: 1px solid lightgray;
}

.groupbordershort {
margin-top: 6pt;
margin-bottom: 10pt;
border: 1px solid lightgray;
}

.groupborder-empty {
margin-top: 6pt;
/*border-radius: 5px;*/
border: 1px solid lightgray;
}

.header {
font-weight: bold;
padding-top: 4pt;
background-color: rgba(0, 0, 0, 0.03);
padding-bottom: 6pt;
border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
margin-bottom: 6pt;
}

.headershort {
font-weight: bold;
padding-top: 4pt;
background-color: rgba(0, 0, 0, 0.03);
padding-bottom: 6pt;
margin-bottom: 0pt;
}

.content {
justify-content: center;
overflow-y: auto;
max-height: calc(100vh - 300px);;
}

.datasetGrouping-header {
cursor: pointer;
color: brown;
text-align: center;
background-color: white;
border: 0;
}

.datasetGrouping-header:hover {
background-color: rgba(0,0,0,0.03);
}

.creator {
font-style: italic;
font-size: smaller;
}

.list-group {
list-style: none;
}

.list-group-item {
/* background-color: lightblue; */
padding: .5rem 1.25rem;
margin-bottom: 8pt;
border-top: 1pt solid lightgray !important;
}

.list-group-item:first-of-type {
margin-top: 10pt;
}

.mapping-item {
background-color: lightgreen;
}

.times {
  font-size: smaller;
}

.executinginfo {
background-color: lightgray;
  font-size: smaller;
  overflow: hidden;
}

.executeinfo {
border-top: 1px solid lightgray;
font-size: smaller;
}

.hidden { display:none; }

.toggle {
padding-left:6pt;
padding-right:6pt;
padding-top:1pt;
padding-bottom:1pt;
margin-left:2pt;
margin-right:2pt;
font-size: 8pt;
}

.nospace {
padding:5pt;
margin:0pt;
}

.intitle {
color: purple;
font-weight: normal;
}

.subject {
color: brown;
}

.predicate-object {
color: purple;
}

.logical-input {
color: green;
}

.nospace2 {
padding:0pt;
margin:0pt;
}
.mainTitle {
font-size: 24pt;
margin-bottom: 6pt;
}
.togglebutton {
font-size: 8pt;
}

.editbutton {
margin-top:2pt;
font-size: 14pt;
}

.bold {
font-weight: bold;
}
.mybutton {
padding-left:8pt;
padding-right:8pt;
}

.actions {
padding: 0pt;
margin: 0pt;
background: red !important;
background-color: transparent !important;
}

.schema-prop-bg-col{
  background-color: burlywood;
}

.pointer {
cursor: pointer;
}

.annotators {
background-color: oldlace;
}
.error {
color: red;
}

.red {
color: Crimson;
overflow-wrap: break-word;
max-height: 48px;
overflow-y: auto;
}

.menubutton {
color:DarkRed !important;
background-color: transparent;
border:none;
color: DimGray;
font-size: 20px;
margin:0pt;
padding:0pt;
box-sizing: border-box;
display:inline-block;
font-kerning:auto;
line-height:20px;
text-align:center;
vertical-align:middle;
font-weight: 700;
float:right;
display:block;
}

.nextbutton {
background-color: transparent;
border:none;
color: DimGray;
font-size: 18pt;
margin:0pt;
padding-left:10pt;
padding-right:10pt;
box-sizing: border-box;
display:inline-block;
font-kerning:auto;
line-height:20px;
text-align:center;
vertical-align:middle;
font-weight: 700;
float:right;
display:block;
}

.menubutton:disabled {
color:lightgray !important;
background-color: white !important;
border:none;
color: white;
font-size: 20px;
margin:0pt;
padding:0pt;
box-sizing: border-box;
display:inline-block;
font-kerning:auto;
line-height:20px;
text-align:center;
vertical-align:middle;
font-weight: 700;
float:right;
display:block;
}


.stategroup {
/*  background-color: lightgray;
border-radius: 5px;
border: 1px solid lightgray; */
margin-left:10pt;
margin-right:10pt;
}

.mappingPadding {
margin:10pt;
}
.mappingRow {
margin-top: 14pt;
background-color: WhiteSmoke;
}

.mappingInstanceRow {
padding-left:5pt;
margin-top:4pt;
margin-bottom:4pt;
background-color: lightgray;
}

.menubutton:hover {
background-color: transparent; /* Green */
color: black;
}

.menubutton.disabled:hover {
background-color: white; /* Green */
color: white;
}

.btn-group.annotationFilters button {
border: 1px white solid;
border-radius: 2px;
}

.addAnnotationRow {
display: flex;
justify-content: center;
}

.approveButton {
background-color: transparent;
border:none;
color: black;
font-size: 15px;
margin: 0 12px 0 0;
padding:0pt;
box-sizing: border-box;
display:inline-block;
font-kerning:auto;
line-height:20px;
text-align:center;
vertical-align:middle;
float:right;
display:block;
}
.approveButton.selected {
font-size: 20px;
color: green;
}
.approveButton:hover {
background-color: transparent; /* Green */
color: green;
}

.deleteeditbutton {
background-color: transparent;
border:none;
color: black;
font-size: 15px;
margin:0pt;
padding:0pt;
box-sizing: border-box;
display:inline-block;
font-kerning:auto;
line-height:20px;
text-align:center;
vertical-align:middle;
float:right;
display:block;
}
.deleteeditbutton.selected {
font-size: 20px;
color: red;
}
.deleteeditbutton:hover {
background-color: transparent; /* Green */
color: red;
}

.deleteaddbutton {
background-color: transparent;
border:none;
color: black;
font-size: 15px;
margin:0pt;
padding:0pt;
box-sizing: border-box;
display:inline-block;
font-kerning:auto;
line-height:20px;
text-align:center;
vertical-align:middle;
float:right;
display:block;
}

.deleteaddbutton:hover {
background-color: transparent; /* Green */
color: black;
}

.actions > .btn-primary {
background-color: transparent;
border:none;
color: DimGray;
font-size: 14px;
margin:0pt;
padding:0pt;
box-sizing: border-box;
display:inline-block;
font-kerning:auto;
line-height:20px;
text-align:center;
vertical-align:middle;
font-weight: 700;
float:right;
display:block;
}

.actions > .btn-primary:hover {
background-color: transparent; /* Green */
color: black;
}

.actions > .btn-primary.dropdown-toggle:focus {
background-color: transparent; /* Green */
color: black;
border:none;
box-shadow: 0 0 0 .0rem black;
}

.optional {
font-style:italic;
}

.executed {
color: DarkGreen ;
}

.failed {
color: DarkRed  ;
}

.not-executed {
color: DarkGrey  ;
}

.executing {
color: DarkBlue  ;
}

.not-indexed {
color: DarkGrey  ;
}

.indexing {
color: DarkCyan  ;
}


.indexed {
color: DarkCyan ;
}

.leaflet-container {
height: 400px;
width: 80%;
margin: 0 auto;
}

.osmmap { width: 600px; height: 400px; }

.subscript {
display: block;
font-style: italic;
font-size: smaller;
}

.punp {
font-family: sans-serif;
font-weight: bold;
font-size: 16pt;
}

.aligninfo {
font-size: smaller;
font-style: italic;
}

.languagetitle {
  background-color: white;
  border: 0;
  font-weight: bold;
  border-radius: 7px;
  height: 26pt;
  padding: 7px;
  color: gray;
}

.languageinfo {
font-size: smaller;
padding-right:5px;
color:purple;
}


.crimson {
font-size: smaller;
color: crimson;
}

.crimson-std {
color: crimson;
}

.blueviolet-std {
color: BlueViolet;
}

.parameter {
font-size: smaller;
color: crimson;
}

.parametervalue {
font-size: smaller;
color: olive;
}

.highlight {
color: crimson;
}

.litlanguage {
color: BlueViolet;
}

.spinner {
display: block;
margin: 0 auto;
}

.thin {
font-weight: normal;
}

.bold {
font-weight: bold;
}

.scroll {
flex: 1;
overflow-y: auto;
}

.scrollContainer {
display: flex;
flex-direction: row;
height:  85vh;
max-height: calc(100vh - 230px);
}

.loader {
margin-top: 10pt;
text-align: center;
}

.alternate:nth-child(even) {
background-color: #CCC;
}

.alternate:nth-child(odd) {
background-color: #FFF;
}

.green {
color: green;
}

.green:hover {
color:DarkGreen
}

.incollection {
color: red;
padding-right: 8pt;
margin: 8pt;
border-right: 1px solid lightgray;
}

.references {
background-color: transparent;
border: none;
color: crimson;
text-align: center;
text-decoration: none;
display: inline-block;
margin-right: 4px;
cursor: pointer;
font-size: smaller !important;
}


.timetag {
background-color: cyan;
}

.placetag {
background-color: green;
}

.termtag {
background-color: pink;
}

.inner {
margin-left: 10pt;
width:inherit;
}

.catalog {
background-color: #fde8ec;
font-weight:600;
color: crimson !important;
}

.selected-item {
background-color: lightgray !important;
}

.selected-item:hover {
background-color: #f2f2f2 ;
}

.catalogselected-item {
background-color: lightgray !important;
font-weight:600;
color: crimson !important;
}

.language {
font-weight: bold;
font-size: smaller;
cursor: pointer;
}

.full {
  width:100%;
}


.tag {
color: crimson;
text-align: center;
text-decoration: none;
cursor: pointer;
font-size: smaller !important;

}

.tag:hover {
text-decoration: none;
}

.tagbutton {
color:DarkRed !important;
background-color: transparent;
border:none;
box-sizing: border-box;
font-kerning:auto;
font-weight: 700;
font-size: smaller !important;
padding-top:2pt;
}

.tagbutton:hover {
background-color: transparent; /* Green */
color: red !important;
}


.tag-tag {
background-color: rgba(0, 0, 0, 0.03);
margin-right:10pt;
border: 1pt solid rgba(0, 0, 0, 0.125);
border-radius:3.33333px;
}

.tag-dash {
margin-right:10pt;
font-weight: bold;
}

.tag-type {
padding:5pt;
font-size: smaller !important;
color: purple;
text-transform: uppercase;
}

.searchheader {
padding:6pt;
margin-bottom:10pt;
border: 1pt  solid rgba(0, 0, 0, 0.125) !important;
border-radius:3.33333px;
}

.searchtags {
padding:0pt;
margin:0pt;
}


.info {
padding:0pt 0pt 0pt 4pt;
margin:0pt;
color: crimson;
}

.appheader {
margin-top: 5px;
margin-bottom: 22px;
padding-top: 2pt;
padding-bottom: 5pt;
border-top: 5px  solid rgba(0, 0, 0, 0.125) !important;
border-bottom: 5px  solid rgba(0, 0, 0, 0.125) !important;
}

.sageLogo {
width: 180px;
}

.projectTitle {
/*font-family: 'Montaga';*/
font-size: 31pt;
font-weight: bold;
/* color: #B84643; */
color: #296C6F;
display:inline;
position: absolute;
bottom: 3px;
margin-left: 14px;
}

.databaseTitle {
color: crimson;
font-weight: bold;
}

.databaseSubTitle {
color: crimson;
}

.accordion-header {
color:black !important;
font-weight: bold;
font-size: 11pt;

}

.accordion-header:hover {
text-decoration: none ;
}

.crbutton {
background-color: crimson;
border-color: crimson;
}

.crbutton:hover,  .crbutton:active, .crbutton:focus {
background-color: darkred !important;
border-color: crimson !important
}

.dataset-control {
padding:0pt 10pt 0pt 0pt;
margin: 0pt 0pt 0pt 0pt;
border-right: 1px solid crimson;
}

.selected-value {
background-color:LightGray !important;
}

.vocabulary-result {
padding:5px;
margin:0pt;
width: 100%;
}

.vocabulary-result:hover {
background-color:#f2f2f2;
}

a {
color:crimson;
}

a:hover {
color:#750b20;
text-decoration:none;
}

.alink {
color:crimson;
}

.alink:hover {
color:#750b20;
text-decoration:none;
}

.lbutton {
background-color: transparent;
border: none;
color: crimson;
text-decoration: none;
display: inline-block;
margin: 4px 2px;
cursor: pointer;
}

.abutton {
background-color: transparent;
border: none;
color: crimson;
text-align: center;
text-decoration: none;
display: inline-block;
margin: 4px 2px;
cursor: pointer;
}

.divider {
padding:0pt;
margin:5pt;
border-bottom: 1pt solid crimson;
width: 100%;
}

.adataset:hover {
background-color:#f2f2f2;;
}

.accordion-body {
margin:20px 6px 12px 0px;
padding:0px;
}

.datasetId {
font-weight: normal !important;
color:purple;
}

.private-dataset {
color: brown;
}

.welcometable {
padding-top: 10pt;
padding-bottom: 10pt;
margin-top: 80pt;
}

.welcome {
font-size: 20pt;
text-align: center !important;
}

nav {
padding-top: 30px;
margin-bottom: 15px;
display: flex;
justify-content: center;
border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}
.nav-tabs .nav-link {
color: #ad0728;
font-weight: bold;
font-size: 12pt;
border: 0;
padding-bottom: 10px;
margin-right: 10px;
}
.nav-tabs .nav-link:last-of-type {
margin-right: 0;
}
.nav-tabs .nav-link.active {
color: crimson;
border-bottom: 2px solid crimson;
}
.tab-content {
padding: 30px;
}
.tab-content button.btn {
float: right;
}

.dashboardSelectorDatasets {
display: flex;
justify-content: flex-end;
}
.dashboardSelectorDatasets button.btn {
margin-top: 8px;
}

.dashboardSelectorEditors {
display: flex;
justify-content: center;
}

.fullwidth {
width: 100%;
}

.accept-bias {
background-color: #ccffcc;
}

.reject-bias {
background-color: #ffe6e6;
}

.progress-line {
height: 3px;
}

.property-value {
min-width: 50%;
}

.validation-create-modal-body {
max-height: calc(100vh - 180px);
overflow-y: auto;
overflow-x: hidden;
}

.validation-modal {
overflow: hidden;
}

.tooltip .arrow::before {
  border-left-color: rgba(108, 117, 125, 1.0);
  border-right-color: rgba(108, 117, 125, 1.0);
}
.tooltip-inner {
  background: rgba(108, 117, 125, 1.0);
  color: white;
}

button#annotator-selector {
  display: inline-flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 4px;
}
button#annotator-selector::after {
  font-family: 'FontAwesome';
  font-weight: 800;
  content: "\f107";
  border: 0;
  font-size: 11pt;
}
#annotator-selector .dropdown .dropdown-menu.show {
  width: 100%;
}
#annotator-selector .dropdown .dropdown-menu.show .dropdown-item:hover {
  background-color: #007bff;
  color: white;
}
.tooltip.annotator-tooltip .arrow::before {
  border-left-color: rgba(255, 255, 255, 1.0);
  border-right-color: rgba(255, 255, 255, 1.0);
  border-top-color: rgba(108, 117, 125, 1.0);
  border-down-color: rgba(108, 117, 125, 1.0);
}

.boxedContainer {
  max-width: 500px;
}

.rangeIncludes {
  font-size: 8pt !important;
  margin-left: 20px;
  color: crimson;
}
