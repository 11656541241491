.showForm {
    animation: showForms 0.2s ease-out forwards;
}

.hideForm {
    opacity:0;
}


@keyframes showForms {
    0%{
        opacity: 0;
        transform: translateX(-100%)
    }
    50%{
        opacity: 1;
        transform: translateX(20%)
    }
    100%{
        opacity: 1;
        transform: translateX(0%)
    }
}

/* @keyframes hideForms{
    0%{
        opacity: 1;
        transform: translateX(0%)
    }
    50%{
        opacity: 0.3;
        transform: translateX(100%)
    }
    100%{
        opacity: 0;
        transform: translateX(1000%)
    }
} */
